table {
    border-collapse: collapse;
    max-width: 800px;
    width: 100%;
}

th {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25vw;
    white-space: nowrap;
}

td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25vw;
    white-space: pre-wrap;
    max-height: 4.2em;
}

img {
    border-radius: 50%;
    width: 125px;
    height: 125px;
}