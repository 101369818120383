.card {
    width: 200px;
    height: 300px;
    border: 1px solid #6441a5;
    position: relative;
    margin: 20px;
    overflow: hidden;
    color: white;
}

.card:hover {
    box-shadow: 0 0 10px 0 #e2012e8e;
}

.background {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 0px 0px 0px 0px;
    top: 0;
    left: 0;
    filter: brightness(40%);
    filter: blur(2px)
}

.profilePhoto {
    width: 100px;
    height: 100px;
    object-fit: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    border: 1px solid #6441a5;
    margin-top: 20px;
}

.displayName {
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    top: 140px;
    left: 8px;
}

.broadcasterType {
    font-size: 1rem;
    font-style: italic;
    position: absolute;
    top: 160px;
    left: 8px;
}


@media screen and (max-width: 600px) {
    .card {
        width: 150px;
        height: 200px;
        margin: 10px 5px 10px 5px;
    }
    .background {
        min-height: 125px;
    }

    .profilePhoto {
        width: 100px;
        height: 100px;
    }

    .displayName {
        font-size: 1rem;
        top: 120px;
    }

    .broadcasterType {
        top: 140px;
    }
}

button {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    background-color: #999999;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
    transition: 0.3s ease-in-out;
}

button:hover {
    background-color: #CCCCCC;
}